@import url("https://rsms.me/inter/inter.css");

html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
}

body {
  background: white;
  font-family: "Inter var", sans-serif;
  display: flex;
  justify-content: center;
}

#root {
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

h1{
  text-align: center;
  font-size: 3rem;
  margin-bottom: 10px;
}

select {
  text-align-last:center;
  background-color: #ffffff !important;
  color: grey;
  border-radius: 10px !important;
  border: 1px solid #fff !important;
  box-shadow: 0 4px 8px rgba(30, 60, 90, 0.2) !important;
  margin-top: 10px !important;
  min-width: 100px;
  height: 50px;
}

.seletores {
  margin-top: 50px;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.selects{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 30px;
}

.main-container{
  display: flex;
  min-height: 200px;
  min-width: 100vh;
  height: 100vh;
}

.produto {
  min-width: 80vw;
}

.produto-focus {
  min-width: 100vw;
}

.menu-titulo {
  color: #fff;
  font-size: 2rem;
}

.active-menu {
  background-color: #12294d;
  min-width: 25vw;
  max-width: 50vw;
  position: relative;
  height: 100vh;
  padding-right: 10px;
  box-shadow: 0px 0px 16px rgba(30, 60, 90, 1);
  z-index: 9999;
}

.disable-menu {
  display: none;
}

.close-hamburguer{
  cursor: pointer;
  float: right;
  margin-top: 10px;
  margin-left: 5px;
}
.hamburguer {
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 2px 4px 8px rgba(30, 60, 90, 0.5);
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  z-index: 9999;
}

.veneziana {
  background-color: #FFA521;
}

.info {
  font-weight: 600;
  padding-left: 60px;
  position: absolute;
  top: 15px;
}